<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="container">
                    <el-form label-width="100px"
                             size="small"
                             :model="form"
                             :rules="rules"
                             :disabled="!isEdit"
                             ref="form">
                        <section>
                            <el-row>
                                <el-col :span="14">
                                    <el-form-item label="通知类型：">
                                        <el-radio-group v-model="form.messageType">
                                            <el-radio :label="vm.value" v-for="vm in messageTypeOptions"
                                                      :key="vm.value">{{
                                                    vm.label
                                                }}
                                            </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="显示方式：" prop="pushType">
                                        <el-checkbox :label="messageDisplayModeOptions[0].value"
                                                     :key="messageDisplayModeOptions[0].value"
                                                     v-model="form.pushType">
                                            {{ messageDisplayModeOptions[0].label }}
                                            <!--                                            <div style="display: flex;align-items:center ">-->
                                            <!--                                                {{ messageDisplayModeOptions[0].label }}-->
                                            <!--                                                <div style="display: flex;align-items: center" v-if="form.pushType">-->
                                            <!--                                                    <span style="color: #333;margin-right: 5px">（ 已阅回执</span>-->
                                            <!--                                                    <el-switch v-model="form.isReceipt"-->
                                            <!--                                                               active-text="打开"-->
                                            <!--                                                               inactive-text="关闭"-->
                                            <!--                                                               :active-value="1"-->
                                            <!--                                                               :inactive-value="0"-->
                                            <!--                                                               active-color="#1890FF"-->
                                            <!--                                                               inactive-color="#999"></el-switch>-->
                                            <!--                                                    <span style="color: #333;margin-right: 5px">&nbsp;）</span>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                        </el-checkbox>
                                        <span style="color: #999;margin-left: 10px">（消息默认发送至消息列表）</span>
                                    </el-form-item>
                                    <el-form-item label="是否循环：" v-if="form.pushType">
                                        <el-row>
                                            <el-col :span="4">
                                                <el-checkbox v-model="form.isRepeat">
                                                    循环播放
                                                </el-checkbox>
                                            </el-col>
                                            <el-col :span="19" :offset="1" v-if="form.isRepeat">
                                                <span>间隔时间：</span>
                                                <el-input-number v-model="form.timeSpan"
                                                                 :min="1"
                                                                 style="width: 160px"
                                                                 :step-strictly="true"
                                                                 placeholder="间隔周期"
                                                                 label="间隔周期"></el-input-number>
                                                <span class="ml10">小时</span>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                    <el-form-item label="标题：" prop="messageTitle">
                                        <el-input placeholder="请输入标题" v-model="form.messageTitle" :maxlength="15"
                                                  show-word-limit/>
                                    </el-form-item>
                                    <el-form-item label="内容：" prop="messageInfo">
                                        <el-input type="textarea" v-model="form.messageInfo"
                                                  placeholder="请输入内容"
                                                  :rows="6"
                                                  :maxlength="300" style="font-size: 14px" show-word-limit/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10" align="center">
                                    <div class="preview-box">
                                        <div v-if="form.pushType">
                                            <img :src="messageTypeOptions[form.messageType].bg" alt="">
                                            <div class="content">
                                                <h2>{{ form.messageTitle }}</h2>
                                                <p>{{ form.messageInfo }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </section>
                        <section>
                            <el-form-item label="发布范围：" prop="rangeType">
                                <el-radio-group v-model="form.rangeType">
                                    <el-radio :label="0">
                                        按城市
                                        <SelectDialog v-if="form.rangeType===0"
                                                      title="城市"
                                                      :api="getCityData"
                                                      params="2"
                                                      @change="handleRangeChange"
                                                      :replace-field="{label:'name',value:'shortCode'}"
                                                      v-model="form.cityIds"
                                                      :isEdit="isEdit"/>
                                    </el-radio>
                                    <el-radio :label="1">
                                        按运营商
                                        <SelectDialog v-if="form.rangeType===1"
                                                      title="运营商"
                                                      :api="getOperatorList"
                                                      @change="handleRangeChange"
                                                      v-model="form.operatorIds"
                                                      :isEdit="isEdit"/>
                                    </el-radio>
                                    <el-radio :label="2">
                                        按供应商
                                        <SelectDialog v-if="form.rangeType===2"
                                                      title="供应商"
                                                      :api="getSupplierList"
                                                      @change="handleRangeChange"
                                                      v-model="form.supplierIds"
                                                      :isEdit="isEdit"/>
                                    </el-radio>
                                </el-radio-group>
                                <div>
                                    <SelectDialog display-mode="panel"
                                                  :api="getCityData"
                                                  v-if="form.rangeType===0&&form.cityIds.length!==0"
                                                  params="2"
                                                  title="城市"
                                                  @change="handleRangeChange"
                                                  :replace-field="{label:'name',value:'shortCode'}"
                                                  v-model="form.cityIds"
                                                  :isEdit="isEdit"
                                    />
                                    <SelectDialog display-mode="panel"
                                                  :api="getOperatorList"
                                                  v-if="form.rangeType===1&&form.operatorIds.length!==0"
                                                  title="运营商"
                                                  @change="handleRangeChange"
                                                  v-model="form.operatorIds"
                                                  :isEdit="isEdit"/>
                                    <SelectDialog display-mode="panel"
                                                  :api="getSupplierList"
                                                  v-if="form.rangeType===2&&form.supplierIds.length!==0"
                                                  title="供应商"
                                                  @change="handleRangeChange"
                                                  v-model="form.supplierIds"
                                                  :isEdit="isEdit"/>
                                </div>
                            </el-form-item>
                            <el-form-item label="发布渠道：" prop="channel">
                                <el-checkbox-group v-model="form.channel">
                                    <el-checkbox v-for="vm in messageChannelOptions" :key="vm.value" :label="vm.value">
                                        {{ vm.label }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </section>
                        <!--                        <div class="mask" v-if="!isEdit"></div>-->
                    </el-form>
                </div>
            </div>

        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <div style="display: flex;align-items: center">
                    <p style="color: #999;font-size: 14px;margin-right: 20px"
                       v-if="form.pushTime">消息将在{{ form.pushTime | dateFormat('YYYY-MM-DD HH:mm') }}定时发布</p>
                    <el-popover placement="top" width="400"
                                title="取消定时发送"
                                v-model="popover.visible1"
                                v-if="form.pushTime&&isEdit">
                        <p>确定取消定时发送吗？</p>
                        <p>取消发送的消息可在【消息列表-未发送】中查看？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button @click="popover.visible1 = false">取消</el-button>
                            <el-button type="primary" @click="handleClearPushTime">确定</el-button>
                        </div>
                        <el-button slot="reference" type="primary" plain>取消定时发送</el-button>
                    </el-popover>
                    <SetDateTimePopover v-model="form.pushTime"
                                        ref="setDateTimePopover"
                                        @onShow="handleSetDateTimePopoverShow"
                                        @submit="handleSubmit"
                                        v-if="form.pushTime&&isEdit"/>
                </div>
                <div style="text-align: right">
                    <el-button @click="handleCancel">取消</el-button>
                    <SetDateTimePopover v-model="form.pushTime"
                                        ref="setDateTimePopover"
                                        @onShow="handleSetDateTimePopoverShow"
                                        @submit="handleSubmit"
                                        v-if="!form.pushTime&&isEdit"/>
                    <el-popover placement="top" width="300" title="发布消息" v-model="popover.visible3" v-if="isEdit">
                        <p>立即发送本条消息？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button @click="popover.visible3 = false" style="width: 64px">取消</el-button>
                            <el-button type="primary" @click="handleSubmit" :loading="loading"
                                       style="width: 64px;margin-left: 10px">确定
                            </el-button>
                        </div>
                        <el-button slot="reference" type="primary" style="margin-left: 20px" :loading="loading">
                            {{ form.id ? '保存修改' : '立即发布' }}
                        </el-button>
                    </el-popover>
                    <!--                    <el-button type="primary" @click="isEdit=true" v-if="!isEdit">编辑</el-button>-->
                </div>
            </div>
        </el-footer>
    </div>
</template>

<script>
import {messageTypeOptions, messageDisplayModeOptions, messageChannelOptions} from '@/data/message'
import SelectDialog from './SelectDialog'
import {getCityData, getOperatorList, getSupplierList} from '@/urls'
import SetDateTimePopover from './SetDateTimePopover'
import {addMessageApi, updateMessageApi, getMessageDetailApi} from "@/www/urls/message";
import dayjs from "dayjs";

const createForm = () => ({
    messageType: messageTypeOptions[0].value,
    pushType: false,
    isReceipt: 0,
    messageTitle: '',
    messageInfo: '',
    rangeType: 0,
    cityIds: [],
    supplierIds: [],
    operatorIds: [],
    channel: [messageChannelOptions[0].value],
    pushTime: null,
    isRepeat: false,// 0不重复  1重复弹框
    timeSpan: 24
})
export default {
    name: "messageAdd",
    components: {
        SelectDialog,
        SetDateTimePopover
    },
    data () {
        return {
            loading: false,
            isEdit: true,
            messageTypeOptions,
            messageDisplayModeOptions,
            messageChannelOptions,
            getCityData,
            getOperatorList,
            getSupplierList,
            form: createForm(),
            popover: {
                visible1: false,
                visible2: false,
                visible3: false,
            },
            rules: {
                // pushType: [
                //     {
                //         required: true,
                //         validator (r, value, cb) {
                //             if (value.length === 0)
                //                 return cb('至少选择一种展示方式')
                //             cb()
                //         }
                //     }
                // ],
                messageTitle: [
                    {required: true, message: '请输入通知标题', trigger: 'blur'}
                ],
                messageInfo: [
                    {required: true, message: '请输入通知内容', trigger: 'blur'}
                ],
                rangeType: [
                    {
                        required: true,
                        validator: (r, val, cb) => {
                            switch (val) {
                                case 0:
                                    if (this.form.cityIds.length === 0)
                                        return cb('请设置发布城市范围')
                                    break;
                                case 1:
                                    if (this.form.operatorIds.length === 0)
                                        return cb('请设置发布运营商范围')
                                    break;
                                case 2:
                                    if (this.form.supplierIds.length === 0)
                                        return cb('请设置发布供应商范围')
                                    break;
                            }
                            cb()
                        },
                        trigger: 'change'
                    },
                ],
                channel: [
                    {
                        required: true,
                        validator: (r, value, cb) => {
                            if (value.length === 0)
                                return cb('请至少设置一个发布渠道')
                            cb()
                        }
                    }
                ],
            }
        }
    },
    activated () {
        if (this.$route.query.id)
            this.getDetail(this.$route.query.id)
        else if (this.form.id) this.form = createForm();
        this.isEdit = !this.$route.query.type;
        this.$refs['form'].clearValidate();
    },
    methods: {
        async handleClearPushTime () {
            try {
                await this.$refs['form'].validate()
                this.form.pushTime = null;
                this.popover.visible1 = false;
                this.form.status = 1;
                this.handleSubmit();
            } catch (err) {
                console.log(err);
            }
        },
        async handleSubmit () {
            this.loading = true
            try {
                await this.$refs['form'].validate()
                let ret;
                const form = {
                    ...this.form,
                    cityIds: this.form.rangeType === 0 ? this.form.cityIds.join(',') : undefined,
                    supplierIds: this.form.rangeType === 2 ? this.form.supplierIds.join(',') : undefined,
                    operatorIds: this.form.rangeType === 1 ? this.form.operatorIds.join(',') : undefined,
                    channel: this.form.channel[0],
                    pushType: this.form.pushType ? 1 : 0,
                    isRepeat: this.form.isRepeat ? 1 : 0,
                    pushTime: this.form.pushTime ? dayjs(this.form.pushTime).valueOf() : null,
                    isReceipt: 1
                }
                if (this.form.id)
                    ret = await updateMessageApi(form)
                else
                    ret = await addMessageApi(form)
                this.loading = false
                if (!ret.success)
                    return this.$message.error(ret.errors[0].message)
                this.$message.success(this.form.id ? '编辑成功~' : '新增成功~');
                this.$router.push(`/messageList?t=${+new Date()}`)
                this.form = createForm();
            } finally {
                this.loading = false
            }
        },
        handleRangeChange () {
            this.$refs['form'].validateField(['rangeType'])
        },
        async getDetail (id) {
            this.loadingShow();
            const ret = await getMessageDetailApi(id)
            this.loadingHide()
            if (ret.success) {
                this.form = {
                    ...ret.data,
                    pushType: ret.data.pushType === 1,
                    isRepeat: ret.data.isRepeat === 1,
                    cityIds: ret.data.cityIds ? ret.data.cityIds.split(',') : [],
                    supplierIds: ret.data.supplierIds ? ret.data.supplierIds.split(',') : [],
                    operatorIds: ret.data.operatorIds ? ret.data.operatorIds.split(',') : [],
                    rangeType: ret.data.cityIds ? 0 : (ret.data.supplierIds ? 2 : 1),
                    channel: [ret.data.channel],
                    pushTime: ret.data.pushTime ? dayjs(ret.data.pushTime).format('YYYY-MM-DD HH:mm') : null
                }
            } else this.$message.error(ret.errors[0].message)
        },
        async handleCancel () {
            try {
                if (this.$route.query.type === '1' && this.isEdit)
                    await this.$confirm('请确认所有修改已保存。', '提示', {
                        type: 'warning'
                    })
                this.$router.push('/messageList')
                this.form = createForm();
            } catch (err) {
                console.log(err);
            }
        },
        //新增 点击定时发送 校验表单是否填写完整 如果填写完整 就 显示时间填写
        async handleSetDateTimePopoverShow () {
            try {
                await this.$refs['form'].validate()
                this.$refs['setDateTimePopover'].show();
            } catch (err) {
                console.log(err);
            }
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-button {
        width: 120px;
        padding: 10px 0;
        font-weight: normal;
    }

    .el-radio__label, .el-checkbox__label {
        font-weight: normal;
    }

    .el-button--warning {
        background-color: #E6A233;
        border-color: #E6A233;
    }

    .el-button--default {
        border: 1px solid #1989FA;
        border-radius: 4px;
        color: #1989FA;
    }
}

.container {
    position: relative;

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 9999;
    }
}

section {
    padding: 20px;
    margin-top: 10px;
    background-color: #fff;
}

.eTrip-footer-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.preview-box {
    background-color: #999;
    height: 300px;
    width: 300px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 10%;
    box-sizing: border-box;
    position: relative;
    align-items: center;

    .content {
        position: relative;
        width: 234px;

        h2 {
            font-size: 14px;
            color: #333;
            text-align: center;
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            padding: 0 16px;
            font-size: 12px;
            color: #333;
            line-height: 20px;
            text-align: left;
            height: 120px;
            overflow: scroll;
        }
    }

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -120px;
        width: 240px;
        transform: translateY(-50%);
    }
}

</style>