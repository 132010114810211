<template>
    <el-popover placement="top"
                width="400"
                title="设置定时发送时间"
                v-model="visible"
                trigger="manual">
        <p>请选择日期及时间。设置成功后不支持修改，如需修改可在设定的时间之前取消</p>
        <p class="mt10 mb10">
            <el-date-picker v-model="dateTime"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width: 100%"
                            :picker-options="pickerOptions"
                            placeholder="选择发送时间"></el-date-picker>
        </p>
        <div style="text-align: right; margin: 0">
            <el-button type="primary" plain @click="visible = false" style="margin-right: 10px">取消</el-button>
            <el-popover placement="top" width="400" title="定时发送" v-model="visible1" trigger="manual">
                <p>确定定时发送吗？</p>
                <p>消息将在{{ dateTime | dateFormat('YYYY-MM-DD HH:mm') }}发送。</p>
                <div style="text-align: right; margin: 0">
                    <el-button @click="visible1=false">取消</el-button>
                    <el-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</el-button>
                </div>
                <el-button type="primary" slot="reference" @click="visible1=true">定时发布</el-button>
            </el-popover>
        </div>
        <el-button slot="reference"
                   :type="value?'primary':'warning'"
                   style="margin-left: 20px;width: 110px"
                   @click="handleShow">
            {{ value ? '更改时间' : '定时发送' }}
        </el-button>
    </el-popover>
</template>

<script>
import dayjs from "dayjs";
export default {
    name: "SetDateTimePopover",
    data () {
        return {
            visible: false,
            visible1: false,
            dateTime: null,
            pickerOptions: {
                disabledDate (date) {
                    return dayjs(date).endOf('d').valueOf() < dayjs().startOf('d').valueOf()
                }
            }
        }
    },
    props: {
        value: {
            type: String,
            default: null
        }
    },
    methods: {
        handleSubmit () {
            this.$emit('input', this.dateTime);
            this.visible1 = false;
            this.visible = false;
            this.$emit('submit')
        },
        handleShow () {
            this.$emit('onShow');
        },
        show () {
            this.visible = true;
        }
    },
    watch: {
        value: {
            handler () {
                this.dateTime = this.value ? new Date(this.value) : new Date()
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>