<template>
    <div class="container">
        <el-button icon="el-icon-plus"
                   size="mini"
                   class="ml10"
                   type="primary"
                   @click="handleOpen"
                   v-if="displayMode==='button'">
            范围
        </el-button>
        <div v-else class="check-label-bar" :class="!isEdit?'showAll':''">
            <el-button icon="el-icon-edit-outline"
                       size="medium"
                       type="text"
                       v-if="isEdit"
                       @click="handleOpen">
                修改
            </el-button>
            <span class="ml10">
                范围：{{ getCheckLabel }}
            </span>
        </div>
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="650px" top="10vh" @close="keyword=''">
            <el-form inline size="small">
                <el-form-item>
                    <el-select style="width: 600px" :placeholder="'输入'+title+'名称搜索'" multiple filterable
                               v-model="checkKeys"
                               clearable>
                        <el-option v-for="vm in getList" :value="vm.value" :label="vm.label" :key="vm.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h3 class="mb10">{{ title }}名称</h3>
            <div class="check-list" v-loading="loading">
                <el-empty description="暂无数据" v-if="getList.length===0"></el-empty>
                <el-checkbox-group v-model="checkKeys" v-else>
                    <ul>
                        <li v-for="vm in getList" :key="vm.value" class="mb10">
                            <el-checkbox :label="vm.value">{{ vm.label }}</el-checkbox>
                        </li>
                    </ul>
                </el-checkbox-group>
            </div>
            <div class="tool-bar">
                <el-radio-group :value="radioType">
                    <el-radio :label="0" @click.native="handleRadioClick(0)">全选</el-radio>
                    <!--                    <el-radio :label="1" @click.native="handleRadioClick(1)">只选常用</el-radio>-->
                </el-radio-group>
                <!--                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>-->
            </div>
            <span slot="footer" class="dialog-footer">
                <span style="font-size: 12px;color: #999;margin-right: 10px">{{ checkKeys.length }}/{{
                        list.length
                    }}</span>
    <el-button @click="dialogVisible = false" size="medium">取 消</el-button>
    <el-button type="primary" @click="handleSubmit" size="medium" style="margin-left: 10px">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "SelectDialog",
    data() {
        return {
            loading: false,
            dialogVisible: false,
            keyword: '',
            checkKeys: [],
            list: [],
            radioType: null
        }
    },
    props: {
        displayMode: {
            type: String,//button panel
            default: 'button'
        },
        title: {
            type: String,
            default: ''
        },
        value: {
            type: Array,
            default: () => []
        },
        api: {
            type: Function,
            default: null
        },
        params: {
            type: [String, Number, Object],
            default: null
        },
        replaceField: {
            type: Object,
            default: () => ({
                label: 'label',
                value: 'value'
            })
        },
        isEdit: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        if (this.displayMode === 'panel')
            this.getData();
    },
    computed: {
        dialogTitle() {
            return `添加接收范围：选择${this.title}`
        },
        getList() {
            return this.list.filter(({label}) => label.indexOf(this.keyword) !== -1)
        },
        getCheckLabel() {
            return this.list.filter(({value}) => this.checkKeys.indexOf(value) !== -1).map(({label}) => label).join('、')
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('input', this.checkKeys)
            this.$emit('change', this.checkKeys)
            this.dialogVisible = false;
        },
        async handleOpen() {
            this.dialogVisible = true;
            this.getData()
        },
        async getData() {
            this.loading = true;
            try {
                const list = await this.api(this, this.params);
                this.list = list.map((item) => ({
                        ...item,
                        label: item[this.replaceField.label],
                        value: item[this.replaceField.value]
                    })
                )

            } finally {
                this.loading = false;
            }
        },
        handleRadioClick(index) {
            if (this._click)
                return
            this._click = true;
            if (this.radioType === index) {
                this.radioType = null;
                this.checkKeys = []
            } else
                this.radioType = index;
            if (this.radioType === 0)
                this.checkKeys = this.list.map(({value}) => value);
            setTimeout(() => {
                this._click = false;
            }, 200)
        }
    },
    watch: {
        value: {
            handler() {
                this.checkKeys = this.value;
            },
            immediate: true
        },
        checkKeys: {
            handler() {
                if (this.list.length === 0)
                    return this.radioType = null;
                if (this.checkKeys.length !== this.list.length)
                    this.radioType = null
                else
                    this.radioType = 0
            },
            immediate: true
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    display: inline-block;
    max-width: 100%;

    &::v-deep {
        .el-button--mini {
            font-size: 14px;
            background-color: #1890FF;
            border-color: #1890FF;
        }
    }
}

.check-list {
    padding: 20px 0;
    height: 40vh;
    overflow: scroll;
}

.tool-bar {
    display: flex;
    justify-content: space-between;
}

.check-label-bar {
    margin-top: 20px;
    display: inline-block;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 1px 20px;
    font-size: 14px;
    color: #333;

    &::v-deep {
        .el-button {
            font-weight: normal;
        }

        .el-icon-edit-outline {
            font-size: 16px;
            margin-top: 1px;
        }
    }

    &.showAll {
        white-space: normal;
        color: #999;
    }
}
</style>
